<template>
  <section class="error-page">
    <h1 class="error-page__title">
      {{ error.statusCode }}
    </h1>
    <div class="error-page__subtitle">
      {{ $t('errors.somethingWentWrong') }}
    </div>
    <div class="error-page__description">
      {{ $t('messageOtherErrorStart') }}
      <a class="error-page__link" @click="back()"> {{ $t('messageNotFoundBackLink') }} </a>,
      {{ $t('orGoTo') }}
      <base-link class="error-page__link" href="/">
        {{ $t('homePage') }}
      </base-link>
      {{ $t('site') }}.
    </div>
  </section>
</template>

<script>
import BaseLink from '@/components/BaseLink.vue';

export default {
  name: 'OtherErrors',
  components: { BaseLink },
  props: {
    /**
     * {
      message: String,
      statusCode: [String, Number],
    }
     */
    error: {
      type: Object,
      required: true,
    },
  },
  methods: {
    back() {
      return window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './errors';
</style>
