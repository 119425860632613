<template>
  <section class="error-page">
    <h1 class="error-page__title">404</h1>
    <div class="error-page__subtitle">
      {{ $t('PageNotFound') }}
    </div>
    <div class="error-page__description">
      {{ $t('messageNotFoundStart') }}
      <a class="error-page__link" @click="back()">
        {{ $t('messageNotFoundBackLink') }}
      </a>
      , {{ $t('orGoTo') }}
      <base-link class="error-page__link" href="/">
        {{ $t('homePage') }}
      </base-link>
      {{ $t('site') }}.
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseLink from '@/components/BaseComponents/BaseLink.vue';

export default {
  name: 'PageNotFound',
  components: { BaseLink },
  head() {
    return {
      title: this.currentPage.title,
    };
  },
  computed: {
    ...mapGetters({
      currentPage: 'page/currentPage',
    }),
  },
  methods: {
    back() {
      return '/';
    },
  },
};
</script>
<style lang="scss" scoped>
@import './errors';
</style>
